.dropdown-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.dropdown-select {
  width: 100%;
  padding: 6px;
  font-size: 0.8125rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
}

.dropdown-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}
