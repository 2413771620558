//
// _footer.scss
//

.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} * 0.75);
  position: absolute;
  right: 0;
  color: var(--#{$prefix}footer-color);
  left: 250px;
  height: $footer-height;
  background-color: var(--#{$prefix}footer-bg);

  @media (max-width: 991.98px) {
    left: 0;
  }
}

.footer-home {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} * 0.75);
  padding-left: calc((1 / 10) * 100vw);
  padding-right: calc((1 / 10) * 100vw);
  position: absolute;
  right: 0;
  left: 0;
  color: var(--#{$prefix}footer-color);

  background-color: var(--#{$prefix}footer-bg);

  @media (max-width: 991.98px) {
    left: 0;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}
