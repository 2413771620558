.header-initial {
  background-color: #0046d5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.logo-name {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.login-button {
  background-color: white;
  color: #0046d5;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

/* Hero */
.hero {
  background-color: #0046d5;
  color: white;
  padding: 100px 20px;
  text-align: center;
}

.cta-button {
  background-color: #ff7f50;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Product Info */
.product-info {
  padding: 50px 20px;
  text-align: center;
}

.product-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.product-images img {
  width: 300px;
  border-radius: 10px;
}

/* Pricing */
.pricing {
  text-align: center;
  padding: 50px 20px;
  background-color: white;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.featured {
  background-color: #0046d5;
  color: white;
}

.signup-button {
  background-color: #0046d5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
  }

  .product-images {
    flex-direction: column;
  }
}
